import React from 'react';
import './index.css';
import './effects/outrun.css';
import './scoped-css/lightmode-toggle.css';
import './scoped-css/sun.css';


class ContentContainer extends React.Component<any, any> {
    render() {
        return (<div id="content-container">
                <div id="main-title">
                    Alex <span className="bright-color-text">Lambson</span>
                </div>
                <div id="content-body">

                    <div className="left-text">
                        <div id="mobile-notice" className="bright-color-text">Mobile site is a work-in-progress.</div>
                        I am a software engineer, occasional public speaker, and a fan of pretentious headphones.
                        <br/><br/>
                        My favorite language is Python, I have nine years of professional experience in object-oriented
                        programming, and
                        eight years of professional experience in software architecting.
                        My academic experience includes four years of experience in C++, Python, Go, and C#.
                        <br/><br/>
                        I like to hike, build LEGOs, do autocross, waste money on diminishing returns in headphone audio
                        quality, and snug dogs.
                        <br/><br/>
                        I graduated Cum Laude from Dixie State University (now Utah Tech) in Computer Science and
                        served on the DSU Student Association for four years.
                        <br/><br/>
                        The positions I served in were: Chief Justice, Computer Science Senator, Web
                        Developer, &amp; Public
                        Relations
                        Committee member.
                        <br/><br/>
                        I was working on a <a href="https://gitlab.com/alexlambson/stellaris-mod-manager">mod profile
                        manager</a> for Paradox Interactive's game <a
                        href="https://www.paradoxplaza.com/stellaris/STST01G-MASTER.html">Stellaris</a>.
                        However, that project was abandoned because I stopped playing the game, and Paradox finally
                        released their own mod manager.
                        I am leaving the project up as an example of my work, or in case other people need the Paradox
                        config parser
                        that I made for it.
                    </div>

                    <div id="photo-dark-mode" className="right-photo">
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-9_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-8_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-7_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-6_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-5_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-4_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-3_s.png"} alt={""}/>
                        <img src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-2_s.png"} alt={"np"}/>
                        <img
                            src={process.env.PUBLIC_URL + "/img/outrun/portrait/alex-lambson-1_s.png"}
                            alt={"Alex Lambson illustrated portrait"}
                        />
                    </div>
                    <div id="photo-light-mode" className="right-photo">
                        <img
                            src={process.env.PUBLIC_URL + "/img/alex-lambson.png"}
                            alt={"Portrait photo of Alex Lambson."}
                        />
                    </div>
                </div>
            </div>);
    }
}

class LightmodeToggle extends React.Component<any, any> {
    render() {
        return (<div className='toggle-switch'>
                <label>
                    <input type='checkbox' onClick={this.props.toggleLightmode}/>
                    <span className='slider'></span>
                </label>
            </div>);
    }
}

class Menu extends React.Component<any, any> {
    render() {
        return (<div id="menu-container" className={this.props.className}>
                <div id="top-bar" className="menu-row">
                    <a href="/">
                        <svg id="Logo" xmlns="http://www.w3.org/2000/svg"
                             x="0px" y="0px" viewBox="0 0 1656 1656">
                            <path id="logo-path" className="svg-main-path" d="M1054.8,1654c0,0,273.9-110,602.9-449.4c0,0-337.4,210.2-543.3,260.4c0,0-88.2-327.8-110.8-723.9
                                c182-328.6,184-603.7-29.1-741.1c0,0-66.6,249-62,713.8c0.1,9.1,0.2,18.1,0.4,27.2c-26.7,44.1-58.2,90.8-95.1,139.1
                                C781.6,546.6,710,189.9,568.8,0c0,0-451.6,657.8-572.2,1653.8c0,0,352.6-107.5,747.2-546.9c12.4-13.8,23.9-28.2,35.5-42.2
                                c24,162,48.9,355.6,74,586.9c0,0,6.5-297.4-23.7-649.8c32.3-41.7,62.2-83,89.6-123.6C947.7,1284.3,1054.8,1654,1054.8,1654z
                                 M179.1,1422.1c0,0,53-656,387-1186.2c0,0,78,59.1,195.5,714C630.5,1104.2,443.6,1270.6,179.1,1422.1z M1018.1,175.4
                                c0,0,71.9,60.6,27.8,258c-7.4,33-22,81.8-47.5,142C997.8,446.7,996.8,302.4,1018.1,175.4z"/>
                        </svg>
                        <div className="menu-row-text">Alex Lambson</div>
                    </a>
                </div>
                <div id="linkedin" className="menu-row">
                    <a href="https://www.linkedin.com/in/alexlambson">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 16">
                            <rect x="1.4" y="1.4" className="svg-background" width="12.9" height="12.9"/>
                            <path className="svg-main-path" d="M13.6,13.6h-2.4V9.9c0-0.9,0-2-1.2-2s-1.4,1-1.4,2v3.8H6.2V6h2.3v1l0,0c0.3-0.6,1.1-1.2,2.2-1.2
                                c2.4,0,2.8,1.6,2.8,3.6v4.2C13.5,13.6,13.6,13.6,13.6,13.6z M3.6,5C2.8,5,2.2,4.3,2.2,3.6c0-0.8,0.6-1.4,1.4-1.4S5,2.8,5,3.6
                                C4.9,4.3,4.3,5,3.6,5z M4.7,13.6H2.4V6h2.4C4.7,6,4.7,13.6,4.7,13.6z M14.8,0H1.2C0.5,0,0,0.5,0,1.2v13.7C0,15.5,0.5,16,1.2,16
                                h13.6c0.7,0,1.2-0.5,1.2-1.2V1.2C16,0.5,15.5,0,14.8,0L14.8,0z"/>
                            <path className="svg-main-path" d="M13.6,13.6h-2.4V9.9c0-0.9,0-2-1.2-2s-1.4,1-1.4,2v3.8H6.2V6h2.3v1l0,0c0.3-0.6,1.1-1.2,2.2-1.2
                                c2.4,0,2.8,1.6,2.8,3.6v4.2C13.5,13.6,13.6,13.6,13.6,13.6z M3.6,5C2.8,5,2.2,4.3,2.2,3.6c0-0.8,0.6-1.4,1.4-1.4S5,2.8,5,3.6
                                C4.9,4.3,4.3,5,3.6,5z M4.7,13.6H2.4V6h2.4C4.7,6,4.7,13.6,4.7,13.6z M14.8,0H1.2C0.5,0,0,0.5,0,1.2v13.7C0,15.5,0.5,16,1.2,16
                                h13.6c0.7,0,1.2-0.5,1.2-1.2V1.2C16,0.5,15.5,0,14.8,0L14.8,0z"/>
                        </svg>
                        <div className="menu-row-text">Linkedin</div>
                    </a>
                </div>
                <div id="github" className="menu-row">
                    <a href="https://github.com/alexlambson">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 16 16">
                            <path className="svg-main-path" d="M8,0C3.6,0,0,3.6,0,8c0,3.5,2.3,6.5,5.5,7.6C5.9,15.7,6,15.4,6,15.2c0-0.2,0-0.7,0-1.4
                            c-2.2,0.5-2.7-1.1-2.7-1.1c-0.4-0.9-0.9-1.2-0.9-1.2c-0.7-0.5,0.1-0.5,0.1-0.5c0.8,0.1,1.2,0.8,1.2,0.8c0.7,1.2,1.9,0.9,2.3,0.7
                            c0.1-0.5,0.3-0.9,0.5-1.1c-1.8-0.2-3.6-0.9-3.6-4c0-0.9,0.3-1.6,0.8-2.1c-0.1-0.2-0.4-1,0.1-2.1c0,0,0.7-0.2,2.2,0.8
                            C6.6,4,7.3,3.9,8,3.9c0.7,0,1.4,0.1,2,0.3c1.5-1,2.2-0.8,2.2-0.8c0.4,1.1,0.2,1.9,0.1,2.1c0.5,0.6,0.8,1.3,0.8,2.1
                            c0,3.1-1.9,3.8-3.7,3.9C9.7,11.8,10,12.3,10,13c0,1.1,0,1.9,0,2.2c0,0.2,0.1,0.5,0.6,0.4C13.7,14.5,16,11.5,16,8C16,3.6,12.4,0,8,0"
                            />
                        </svg>
                        <div className="menu-row-text">GitHub</div>
                    </a>
                </div>

                <div id="rebrickable" className="menu-row">
                    <a href="https://rebrickable.com/users/AlexLambson/mocs/">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 198 198.2">
                            <path className="svg-main-path" d="M98.9,0C84,0,71.8,5.1,71.8,11.4c0,1.2,0.4,2.3,1.2,3.4
                                l-0.1,0c15.3,11.9,45.2,8.5,52.2-1.1v0.5c0.5-0.9,0.8-1.8,0.8-2.8l0-0.1C125.9,5,113.8,0,98.9,0L98.9,0z M125.2,15.2l-0.4,11.9
                                c-9.7,11-39.9,13.1-53.4-0.2l0.4-11.4l-16.1,8.9c-1.4-0.1-2.9-0.2-4.4-0.2c-15,0-27.1,5.1-27.1,11.4c0,1.5,0.7,2.9,2,4.2
                                c15.5,11.2,44.6,7.9,51.5-1.6l-0.4,13.4c-9.7,11-39.9,13.1-53.4-0.2l0.3-9.4L3.5,53.5L99.8,106l96.2-52.8l-22.1-11.9l-0.3,9.7
                                c-9.7,11-39.9,13.1-53.4-0.2l0.4-13c14.8,12.9,45.7,9.7,53.2,0c0,0,0,0,0-0.1c0.4-0.8,0.7-1.6,0.7-2.5l0-0.1
                                C174.3,29,162.2,24,147.3,24c-1.8,0-3.6,0.1-5.4,0.2L125.2,15.2L125.2,15.2z M126.4,64.2l-0.4,13.4c-9.7,11-39.9,13.1-53.4-0.2
                                l0.4-13C88,77.3,119.2,74.1,126.4,64.2z"/>
                            <path className="svg-main-path" d="M198,68.3l-90.1,53V198
                                l90.1-48V68.3z"/>
                            <path className="svg-main-path" d="M0.5,70.1l89.6,50.5v76.7
                                L0,149.3L0.5,70.1z"/>
                        </svg>
                        <div className="menu-row-text">Lego MOCs</div>
                    </a>
                </div>
                <div id="menu-pusher"></div>
                <div id="lightmode-toggle" className="menu-row">
                    <div>
                        <div className="menu-icon-no-svg">
                            <div className={"gg-sun"}>
                                {/*<div>D</div>*/}
                            </div>
                        </div>
                        <div className="menu-content-no-text">
                            <LightmodeToggle toggleLightmode={this.props.toggleLightmode}/>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

interface SvgBackgroundLayerProps {
    /** The layer number, must match the number in the css,
     * e.g. css class "fb-layer-01" will need "01" for this class */
    layerNumber: string,
    /** The scroll-speed offset for this layer. 1.0 is normal, 0.5 is slowed by half, 1.5 is 50% faster */
    zOffset: Number,
    /** How far offset the layer should be initially. Accepts css values, e.g. "-11vh" */
    zInitialOffset: string,
    /** The theme for the layer. This is the subfolder in the imgs folder */
    backgroundTheme: string,
    backgroundFileName: string,
    /** If true, the svg tag will not be generated. Used for e.g. the sky in outrun */
    hideImage: boolean;
    colorFillBackgroundColor: string,
}

class SvgBackgroundLayer extends React.Component<SvgBackgroundLayerProps, any> {
    public static defaultProps = {
        hideImage: false,
        colorFillBackgroundColor: "transparent",
        zOffset: 0,
        zInitialOffset: "0",
        backgroundTheme: "",
        backgroundFileName: "",
    };

    render() {
        const layerId = `fb-layer-${this.props.layerNumber}`;
        const imgLink = process.env.PUBLIC_URL + `/img/${this.props.backgroundTheme}/${this.props.backgroundFileName}`;
        const layerInline = {
            "--offset": this.props.zOffset, "--initial-offset": this.props.zInitialOffset,
        } as React.CSSProperties

        let imgTag = <svg data-src={imgLink} data-cache="disabled" data-unique-ids="disabled"></svg>
        return (<div id={layerId} className="z-offset" style={layerInline}>
                {this.props.hideImage ? null : imgTag}
                <div className="color-fill" style={{backgroundColor: this.props.colorFillBackgroundColor}}></div>
            </div>);
    }
}


class MainContainer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            lightmodeEnabled: false,
            mobileMenuEnabled: false,
        }
        this.toggleLightmode = this.toggleLightmode.bind(this);
        this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
    }

    toggleLightmode() {
        this.setState((state: any) => {
            return {lightmodeEnabled: !state.lightmodeEnabled}
        })
    }

    toggleMenuMobile() {
        this.setState((state: any) => {
            return {mobileMenuEnabled: !state.mobileMenuEnabled}
        })
    }

    render() {
        let lightmodeClass = this.state.lightmodeEnabled ? "eye-cancer" : "";
        let mobileMenuClass = this.state.mobileMenuEnabled ? "toggled" : "";
        let mobileMenuOpenClass = this.state.mobileMenuEnabled ? "mobile-menu-open" : "";
        let mainContainerClasses = [mobileMenuOpenClass, lightmodeClass].join(" ").trim()
        return (<div id="main-container" className={mainContainerClasses}>
            <div id="hamburger-container">
                <div id="hamburger" onClick={this.toggleMenuMobile}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <Menu toggleLightmode={this.toggleLightmode} className={mobileMenuClass}/>
            <div id="right-container">
                <div id="svg-background">

                    <div className="scanlines">
                        <SvgBackgroundLayer
                            layerNumber={"00"}
                            hideImage={true}
                        />
                        <SvgBackgroundLayer
                            layerNumber={"01"}
                            zOffset={0.1}
                            zInitialOffset={"-11vh"}
                            backgroundTheme={"outrun"}
                            backgroundFileName={"layer-01-sun.svg"}
                        />
                        <SvgBackgroundLayer
                            layerNumber={"02"}
                            zOffset={.75}
                            zInitialOffset={"60vh"}
                            backgroundTheme={"outrun"}
                            backgroundFileName={"layer-02-mountains.svg"}
                            colorFillBackgroundColor={"var(--fb--layer-02-fill)"}
                        />
                    </div>
                    <SvgBackgroundLayer
                        layerNumber={"03"}
                        zOffset={1}
                        zInitialOffset={"72vh"}
                        backgroundTheme={"outrun"}
                        backgroundFileName={"layer-03-city.svg"}
                        colorFillBackgroundColor={"var(--fb--layer-03-fill)"}
                    />
                </div>
                <ContentContainer/>
            </div>
        </div>);
    }
}

function App() {
    return <MainContainer />;
}

export default App;
